import styled from "styled-components";

const StyledRulesSection = styled.div`
	@media only screen and (min-width: 2350px) {
		width: 1400px;
		margin: auto;
		grid-row-gap: 70px;
		padding: 70px;

		.about_rules-content-top .headline,
		.about_rules-content-bottom .headline {
			margin-bottom: 30px;
		}
		.about_rules-content-top .headline h2,
		.about_rules-content-bottom .headline h2 {
			font-size: 36px;
			font-weight: 500;
		}
		.about_rules-content-top p,
		.about_rules-content-bottom p {
			line-height: 40px;
			margin-bottom: 20px;
			font-size: 22px;
		}
	}

	display: grid;
	grid-template-columns: 635px auto;
	grid-template-rows: auto auto;
	column-gap: 40px;
	padding: 70px;
	box-sizing: border-box;

	.about_rules-photo {
		grid-column: 1/2;
		grid-row: 1/3;
		min-height: 685px;
		background-position: center center;
		background-repeat: no-repeat;
	}
	.about_rules-content-top {
		grid-column: 2/3;
		grid-row: 1/2;
	}
	.about_rules-content-bottom {
		grid-column: 2/3;
		grid-row: 2/3;
	}
	.about_rules-content-top,
	.about_rules-content-bottom {
		align-self: center;
	}
	.about_rules-content-top h2,
	.about_rules-content-bottom h2 {
		font-size: 24px;
		font-weight: 500;
	}
	.about_rules-content-top p,
	.about_rules-content-bottom p {
		line-height: 30px;
		margin-bottom: 20px;
		font-size: 16px;
	}

	@media only screen and (max-width: 1200px) {
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto auto auto;
		grid-row-gap: 30px;
		box-sizing: border-box;
		padding: 50px 30px;

		.about_rules-photo {
			grid-column: 1/2;
			grid-row: 2/3;
			width: 100%;
			height: 405px;
			min-height: 405px;
			background-position: center center;
			background-size: auto 100%;
			background-repeat: no-repeat;
		}
		.about_rules-content-top {
			grid-column: 1/2;
			grid-row: 1/2;
		}
		.about_rules-content-bottom {
			grid-column: 1/2;
			grid-row: 3/4;
		}
	}
`;

export default StyledRulesSection;
