import styled from "styled-components";

const StyledRulesSection = styled.div`
	@media only screen and (min-width: 2350px) {
		margin: auto;
		margin-top: 0;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;

		.about_leaders-content {
			grid-column: 1/2;
			grid-row: 1/2;
			text-align: center;
			align-self: center;
		}
		.about_leaders-content .about_leaders-text {
			margin: auto;
			width: 700px;
			padding: 20px 60px;
			text-align: left;
		}
		.about_leaders-top-right,
		.about_leaders-bottom-left,
		.about_leaders-bottom-right {
			height: 800px;
		}
	}

	.about_leaders-top-right,
	.about_leaders-bottom-left,
	.about_leaders-bottom-right,
	.about_leaders-top-left {
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}

	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;

	.about_leaders-content {
		grid-column: 1/2;
		grid-row: 1/2;
		text-align: center;
		align-self: center;
		padding: 0 100px;
	}
	.about_leaders-content .about_leaders-text {
		padding: 20px 60px;
		text-align: left;
	}
	.about_leaders-top-right {
		grid-column: 2/3;
		grid-row: 1/2;
		height: 500px;
	}
	.about_leaders-bottom-left {
		grid-column: 1/2;
		grid-row: 2/3;
		height: 500px;
	}
	.about_leaders-bottom-right {
		grid-column: 2/3;
		grid-row: 2/3;
		height: 500px;
	}

	@media only screen and (max-width: 1200px) {
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto auto auto auto;
		width: 100%;

		.about_leaders-content {
			grid-column: 1/2;
			grid-row: 1/2;
			text-align: center;
			position: static;
			height: auto;
			padding: 0;
		}
		.about_leaders-content .about_leaders-text {
			position: static;
			text-align: left;
			box-sizing: border-box;
			padding: 50px 30px;
		}
		.about_leaders-top-right {
			grid-column: 1/2;
			grid-row: 2/3;
			height: auto;
		}
		.about_leaders-bottom-left {
			grid-column: 1/2;
			grid-row: 3/4;
			height: auto;
		}
		.about_leaders-bottom-right {
			grid-column: 1/2;
			grid-row: 4/5;
			height: auto;
		}
	}
`;

export default StyledRulesSection;
