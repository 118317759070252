import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledButtonHashLink = styled(props => <div {...props} />)`
	.button-icon {
		margin-right: 10px;
	}
	a.link {
		min-width: 50px;
		min-height: 40px;
		background: none;
		border: none;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding: 10px 20px 10px 20px;
		text-decoration: none;
		cursor: pointer;
	}
	a.link.green-font {
		color: #2aad7c;
		border: 1px solid #2aad7c;
	}
	a.link.green-font:hover {
		background: #2aad7c;
		color: #ffffff;
	}
	a.link.white-font {
		color: #ffffff;
		border: 1px solid #ffffff;
	}
	a.link.white-font:hover {
		background: #ffffff;
		color: #161616;
	}
	a.link.black-font {
		color: #161616;
		border: 1px solid #161616;
	}
	a.link.black-font:hover {
		background: #161616;
		color: #ffffff;
	}
	a.link.grey-font {
		color: #b0b0b0;
		border: 1px solid #b0b0b0;
	}
	a.link.grey-font:hover {
		border: 1px solid #5b5b5b;
		background: #5b5b5b;
		color: #ffffff;
	}
	a.link.green-bg {
		background: #2aad7c;
		color: #ffffff;
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
		a.link {
			display: block;
			width: 100%;
			min-height: 40px;
			padding: 10px 20px 10px 20px;
			box-sizing: border-box;
			text-align: center;
		}
	}
	@media only screen and (max-width: 1200px) and (min-device-width: 1200px) {
		min-width: 220px;
		width: 250px;
		margin: auto;
	}
`;

const ButtonHashLink = ({ classString, link, label, alt, ...rest }) => (
	<StyledButtonHashLink className="button-link">
		<Link
			className={classString}
			to={link}
			style={{ display: "inline-block" }}
			alt={alt}
			{...rest}
		>
			{label}
		</Link>
	</StyledButtonHashLink>
);

ButtonHashLink.propTypes = {
	classString: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	alt: PropTypes.string
};

ButtonHashLink.defaultProps = {
	alt: ""
};

export default ButtonHashLink;
