import { useStaticQuery, graphql } from "gatsby";

const logo = () => {
	return useStaticQuery(
		graphql`
			{
				icon_analysis: file(relativePath: { eq: "icon-analysis.svg" }) {
					publicURL
				}
				icon_code: file(relativePath: { eq: "icon-code.svg" }) {
					publicURL
				}
				icon_earth: file(relativePath: { eq: "icon-earth.svg" }) {
					publicURL
				}
				icon_edits: file(relativePath: { eq: "icon-edits.svg" }) {
					publicURL
				}
				icon_first: file(relativePath: { eq: "icon-first.svg" }) {
					publicURL
				}
				icon_idea: file(relativePath: { eq: "icon-idea.svg" }) {
					publicURL
				}
				icon_start: file(relativePath: { eq: "icon-start.svg" }) {
					publicURL
				}
				icon_user: file(relativePath: { eq: "icon-user.svg" }) {
					publicURL
				}
			}
		`
	);
};

export default logo;
