import React from "react";
import PropTypes from "prop-types";
import Image from "../Image";

const FactItem = ({ icon, fact, count }) => {
	return (
		<div className="about_facts-item">
			<div className="icon">
				<Image data={icon} alt="synetech-icon" />
			</div>
			<div className="fact">{fact}</div>
			<div className="count">{count}</div>
		</div>
	);
};

FactItem.propTypes = {
	icon: PropTypes.shape({
		publicUrl: PropTypes.string
	}).isRequired,
	fact: PropTypes.string.isRequired,
	count: PropTypes.string.isRequired
};

export default FactItem;
