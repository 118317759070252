import styled from "styled-components";

const StyledFactSection = styled.div`
	@media only screen and (min-width: 2350px) {
		.about_facts-item .fact {
			font-size: 22px;
			margin-bottom: 20px;
		}
		.about_facts-item .count {
			font-size: 22px;
		}
	}

	text-align: center;
	margin: auto;
	width: 1060px;
	padding: 50px 0 50px 0;

	h2 {
		margin-bottom: 40px;
	}
	.about_facts-items {
		display: grid;
		grid-template-columns: 250px 250px 250px 250px;
		grid-template-rows: auto;
		column-gap: 20px;
	}
	.about_facts-item {
		box-shadow: 0 3px 0 rgba(90, 122, 190, 0.12);
		border-radius: 10px;
		border: 1px solid rgba(90, 122, 190, 0.08);
		text-align: center;
		padding: 20px;
	}
	.about_facts-item .icon img {
		height: 30px;
	}
	.about_facts-item .fact {
		margin-top: 20px;
		height: 50px;
	}
	.about_facts-item .count {
		display: inline;
		padding: 5px;
		background: #f0fffa;
		color: #2aad7c;
	}
	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;

export default StyledFactSection;
