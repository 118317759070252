import React from "react";
import PropTypes from "prop-types";
import ButtonHashLink from "../ButtonHashLink";
import CommonContent from "../CommonContent";

import StyledCTA from "./style";

const CTA = ({ headline, content, pageContext, ...rest }) => (
	<StyledCTA>
		<CommonContent
			headline={headline}
			content={content}
			buttons={[
				<ButtonHashLink
					link={`/${pageContext.lang}/${pageContext.MENU.CONTACT.URI}#contact-form`}
					label={pageContext.CALL_US_CTA}
					classString="link green-font"
					alt={pageContext.MENU.CONTACT.NAV}
				/>
			]}
			{...rest}
		/>
	</StyledCTA>
);

CTA.propTypes = {
	headline: PropTypes.string.isRequired,
	content: PropTypes.string,
	pageContext: PropTypes.object.isRequired
};

CTA.defaultProps = {
	content: ""
};

export default CTA;
