import styled from "styled-components";

const StyledAboutUsSection = styled.div`
	@media only screen and (min-width: 2350px) {
		grid-template-rows: 700px;
		padding: 70px;
	}

	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 530px;
	column-gap: 30px;
	padding: 70px 0;
	box-sizing: border-box;

	& > div:nth-child(2n-1) {
		grid-column: 1/2;
		grid-row: 1/2;
		align-self: center;
		padding: 0 10px;
		box-sizing: border-box;
	}
	& > div:nth-child(2n) {
		grid-column: 2/3;
		grid-row: 1/2;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% auto;
	}
	@media only screen and (max-width: 1200px) {
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto 300px;
		grid-row-gap: 30px;
		box-sizing: border-box;
		padding: 50px 30px;

		& > div:nth-child(2n-1) {
			grid-column: 1/2;
			grid-row: 1/2;
		}
		& > div:nth-child(2n) {
			grid-column: 1/2;
			grid-row: 2/3;
			background-size: contain;
		}
	}
`;

export default StyledAboutUsSection;
