import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import StyledTeamMember from "./style";

const TeamMember = ({ name, teamRole, about, image, className }) => {
	return (
		<StyledTeamMember className={className}>
			<Img className="team-image" fluid={image} alt={`${name} - ${teamRole}`} />
			<div className="team-member">
				<div className="name">{name}</div>
				<div className="teamRole">{teamRole}</div>
			</div>
			<div className="team-member-hover-content">
				<div className="team-member">
					<div className="name">{name}</div>
					<div className="teamRole">{teamRole}</div>
				</div>
				<div className="member-description">{about}</div>
			</div>
		</StyledTeamMember>
	);
};

TeamMember.propTypes = {
	image: PropTypes.object.isRequired,
	className: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	teamRole: PropTypes.string.isRequired,
	about: PropTypes.string
};

TeamMember.defaultProps = {
	about: ""
};

export default TeamMember;
