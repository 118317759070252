import styled from "styled-components";

const StyledCTA = styled.div`
	text-align: center;
	width: 100%;
	padding: 70px 30px;
	box-sizing: border-box;

	@media only screen and (max-width: 1200px) {
		padding: 50px 30px;
	}
`;

export default StyledCTA;
