import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ContactRow from "../components/ContactRow";
import ContentRow from "../components/ContentRow";
import Main from "../components/Main";
import CommonContent from "../components/CommonContent";
import TeamMember from "../components/TeamMember";
import FactSection from "../components/FactSection";
import SEO from "../components/seo";
import StyledAboutUsSection from "../components/templateStyles/StyledAboutUsSection";
import StyledRulesSection from "../components/templateStyles/StyledRulesSection";
import StyledLeaderSection from "../components/templateStyles/StyledLeaderSection";
import StyledContactSection from "../components/templateStyles/StyledContactSection";
import StyledContactTextSection from "../components/templateStyles/StyledContactTextSection";
import CTA from "../components/CTA";
import BGImg from "../components/BGImg";

const KnowUs = styled.div`
	@media only screen and (min-width: 2350px) {
		.about_know-us-section {
			margin-bottom: 100px;
		}
	}

	.about_know-us-section {
		margin: auto;
		text-align: center;
		width: 650px;
		margin-bottom: 70px;
	}

	@media only screen and (max-width: 1200px) {
		.about_know-us-section {
			width: 100%;
			box-sizing: border-box;
			padding: 50px 30px;
		}
	}
`;

const About = ({ data, pageContext }) => {
	return (
		<Layout pageContext={pageContext}>
			<Main className="page-container">
				<SEO
					title={pageContext.ABOUT_US_META_TITLE}
					description={pageContext.ABOUT_US_META_DESCRIPTION}
					lang={pageContext.lang}
					keywords={["SYNETECH"]}
				/>
				<BGImg hookData={data.about_header} style={{ height: "100vh" }} />
				<ContentRow background="white" rowContentClass="no-padding">
					<StyledAboutUsSection>
						<CommonContent
							contentSize="big"
							headline={pageContext.ABOUT_US_HEADLINE}
							content={pageContext.ABOUT_US_CONTENT}
							wrapperClass="about_us-content-left"
						/>
						<Img
							fluid={data.about_founders.childImageSharp.fluid}
							objectFit="scale-down"
						/>
					</StyledAboutUsSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding">
					<KnowUs>
						<CommonContent
							headline={pageContext.ABOUT_KNOW_HEADLINE}
							content={pageContext.ABOUT_KNOW_CONTENT}
							wrapperClass="about_know-us-section"
						/>
					</KnowUs>
				</ContentRow>
				<ContentRow rowContentClass="full-size" background="sand">
					<BGImg
						hookData={data.about_photo}
						style={{ height: "100vh", maxHeight: "1000px" }}
					/>
				</ContentRow>
				<ContentRow background="green" rowContentClass="no-padding">
					<StyledRulesSection>
						<Img
							fluid={data.about_photo_green.childImageSharp.fluid}
							objectFit="scale-down"
							className="about_rules-photo"
						/>
						<CommonContent
							headline={pageContext.ABOUT_WORK_HEADLINE_1}
							content={pageContext.ABOUT_WORK_CONTENT_1}
							wrapperClass="about_rules-content-top"
						/>
						<CommonContent
							headline={pageContext.ABOUT_WORK_HEADLINE_2}
							content={pageContext.ABOUT_WORK_CONTENT_2}
							wrapperClass="about_rules-content-bottom"
						/>
					</StyledRulesSection>
				</ContentRow>
				<ContentRow rowContentClass="full-size" background="white">
					<StyledLeaderSection>
						<div className="about_leaders-content">
							<CommonContent
								headline={pageContext.ABOUT_LEADERS_HEADLINE}
								content={pageContext.ABOUT_LEADERS_CONTENT}
								wrapperClass="about_leaders-text"
							/>
						</div>
						<TeamMember
							className="about_leaders-top-right about_square"
							image={data.about_ceo.childImageSharp.fluid}
							name="Vratislav Zima"
							teamRole="CEO"
							about={pageContext.ABOUT_CEO_DESCRIPTION}
						/>
						<TeamMember
							className="about_leaders-bottom-left about_square"
							image={data.about_cto.childImageSharp.fluid}
							name="Daniel Rutkovský"
							teamRole="CTO"
							about={pageContext.ABOUT_CTO_DESCRIPTION}
						/>
						<TeamMember
							className="about_leaders-bottom-right about_square"
							image={data.about_sa.childImageSharp.fluid}
							name="Miroslav Voda"
							teamRole="Solution Architect"
							about={pageContext.ABOUT_SA_DESCRIPTION}
						/>
					</StyledLeaderSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding">
					<StyledContactTextSection>
						<CommonContent
							headline={pageContext.ABOUT_CONTACTS_HEADLINE}
							content={pageContext.ABOUT_CONTACTS_CONTENT}
							wrapperClass="about_contacts-text"
						/>
					</StyledContactTextSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="full-size">
					<StyledContactSection>
						<TeamMember
							image={data.about_ios.childImageSharp.fluid}
							className="about_contacts-bottom-left about_square"
							name="Jiří Rychlovský"
							teamRole="Project Manager & Android Developer"
						/>
						<TeamMember
							image={data.about_android.childImageSharp.fluid}
							className="about_contacts-bottom-right about_square"
							name="Eduard Fuzessery"
							teamRole="Project Manager & iOS Developer"
						/>
					</StyledContactSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding">
					<FactSection
						className="about_facts-section"
						headline={pageContext.ABOUT_FACTS_HEADLINE}
						items={pageContext.ABOUT_FACTS_LIST}
					/>
				</ContentRow>
				<ContentRow background="green-light" rowContentClass="no-padding">
					<CTA
						headline={pageContext.ABOUT_CALL_US_HEADLINE}
						pageContext={pageContext}
					/>
				</ContentRow>
				<ContactRow pageContext={pageContext} />
			</Main>
		</Layout>
	);
};

About.propTypes = {
	pageContext: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array
		])
	).isRequired
};
export default About;

export const query = graphql`
	query AboutQuery {
		about_header: file(relativePath: { eq: "about-header.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid
				}
			}
		}
		about_founders: file(relativePath: { eq: "about-founders.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		about_photo: file(relativePath: { eq: "about-photo.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid
				}
			}
		}
		about_photo_green: file(relativePath: { eq: "about-photo-green.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		about_ceo: file(relativePath: { eq: "about-ceo.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		about_cto: file(relativePath: { eq: "about-cto.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		about_sa: file(relativePath: { eq: "about-sa.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		about_ios: file(relativePath: { eq: "about-ios.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		about_android: file(relativePath: { eq: "about-android.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`;
