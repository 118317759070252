import styled from "styled-components";

const StyledTeamMember = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	padding: 20px 55px;
	box-sizing: border-box;
	&:hover .team-member-hover-content {
		display: block;
		padding: 100px 60px 0 60px;
		box-sizing: border-box;
	}
	&:hover > .team-member {
		display: none;
	}
	.team-image {
		position: absolute !important;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	.team-member {
		position: absolute;
		width: 350px;
		bottom: 20px;
		left: 50px;
		margin: 0;
		text-align: left;
		color: #ffffff;
		line-height: 30px;
		font-weight: 400;
		box-sizing: border-box;
		margin-bottom: 30px;
	}
	.name {
		font-size: 30px;
	}
	.teamRole {
		font-size: 14px;
	}
	.team-member-hover-content {
		display: none;
		background: #161616;
		width: 100%;
		height: 100%;
		opacity: 0.8;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
	}
	.team-member-hover-content .team-member {
		position: static;
	}
	.team-member-hover-content .member-motto,
	.team-member-hover-content .member-description {
		color: #ffffff;
		box-sizing: border-box;
		margin-bottom: 30px;
	}
	.team-member-hover-content .member-description {
		line-height: 30px;
	}
	@media only screen and (min-width: 2350px) {
		.team-member {
			width: 450px;
			line-height: 40px;
		}
		.name {
			font-size: 36px;
		}
		.teamRole {
			font-size: 20px;
		}
		.team-member-hover-content .member-description {
			font-size: 26px;
			line-height: 36px;
		}
	}
	@media only screen and (max-width: 1200px) {
		height: 0;
		padding-bottom: 64%;
		position: relative;
		&:hover .team-member-hover-content {
			display: none;
		}
		&:hover > .team-member {
			display: block;
			height: 100%;
			top: 70%;
		}
		.team-member {
			position: absolute;
			top: 70%;
			left: 0;
			right: 0;
			width: auto;
			margin: 0;
			text-align: center;
			color: #ffffff;
			line-height: 22px;
			font-weight: 400;
		}
		.name {
			font-size: 18px;
		}
		.teamRole {
			font-size: 14px;
		}
	}
	&:nth-child(3) .team-member {
		@media only screen and (max-width: 1200px) {
			color: #000;
		}
	}
`;
export default StyledTeamMember;
