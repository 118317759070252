import styled from "styled-components";

const StyledContactSection = styled.div`
	@media only screen and (min-width: 2350px) {
		margin: auto;
		margin-top: 0;
		width: 100%;
	}

	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto;

	.about_contacts-bottom-left,
	.about_contacts-bottom-right {
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 800px;
		position: relative;
	}

	.about_contacts-bottom-left {
		grid-column: 1/2;
		grid-row: 1/2;
		height: 500px;
	}
	.about_contacts-bottom-right {
		grid-column: 2/3;
		grid-row: 1/2;
		height: 500px;
	}

	@media only screen and (max-width: 1200px) {
		grid-template-columns: auto;

		.about_contacts-bottom-left {
			width: 100%;
			grid-column: 1/2;
			grid-row: 1/2;
			height: auto;
		}
		.about_contacts-bottom-right {
			width: 100%;
			grid-column: 1/2;
			grid-row: 2/3;
			height: auto;
		}
	}
`;

export default StyledContactSection;
