import styled from "styled-components";

const StyledContactSection = styled.div`
	padding: 70px;
	text-align: center;
	margin: auto;
	width: 650px;

	@media only screen and (max-width: 1200px) {
		box-sizing: border-box;
		width: 100%;
		padding: 0;
		.about_contacts-text {
			text-align: left;
			padding: 50px 30px;
		}
	}
`;

export default StyledContactSection;
