import React from "react";
import PropTypes from "prop-types";
import StyledFactSection from "./style";
import FactItem from "./FactItem";
import icons from "../../hooks/icons";
import key from "../../utils/string2key";

const FactSection = ({ headline, items }) => {
	const factIcons = icons();
	return (
		<StyledFactSection>
			<h2>{headline}</h2>
			<div className="about_facts-items">
				{items.map(item => (
					<FactItem
						icon={factIcons[item.IMAGE]}
						fact={item.HEADLINE}
						count={item.CONTENT}
						key={key(item.HEADLINE)}
					/>
				))}
			</div>
		</StyledFactSection>
	);
};

FactSection.propTypes = {
	headline: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			HEADLINE: PropTypes.string.isRequired,
			CONTENT: PropTypes.string.isRequired,
			IMAGE: PropTypes.string.isRequired
		})
	).isRequired
};

export default FactSection;
